import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Feed, Divider, Icon } from 'semantic-ui-react';
import { GetData, PostData, API_Request, API_ADDRESS } from '../utils/auth';

import Logo from '../img/niksu.jpg';

class EditUser extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: {},
            selected_file: null,
            description: "",
            password: "",
            redirect_to_user: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this._AvatarUpload = this._AvatarUpload.bind(this);
        this._UpdateUser = this._UpdateUser.bind(this);
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(local_data._id !== this.props.router.match.params.id)
        {
            this.setState({ redirect_to_user: true });
        }
        else
        {
            GetData("/users/" + this.props.router.match.params.id,
            (res) => {
                this.setState({ loaded: true, data: res.data, description: res.data.description });
            },
            (err) => {
                this.setState({ loaded: true, error: err.error });
            });
        }
    }

    _AvatarUpload()
    {
        const formData = new FormData();

        formData.append(
            "avatar",
            this.state.selected_file,
            this.state.selected_file.name
        );

        API_Request("/img/upload", "POST", {}, formData,
        (res) => {
            this.setState((prevState) => {
                let new_data = prevState.data;
                new_data.avatar = res.data.avatar_id;
                return { data: new_data };
            });
            window.location.reload();
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    _UpdateUser()
    {
        let update_data = {};

        if(this.state.description.length) // && this.state.data.description !== this.state.description
        {
            update_data.description = this.state.description;
        }

        if(this.state.password.length)
        {
            update_data.password = this.state.password;
        }

        API_Request("/users/" + this.state.data._id, "PUT", { "Content-Type": "application/json" }, JSON.stringify(update_data),
        (res) => {
            this.setState({ redirect_to_user: true });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    onFileChange(event) { 
        this.setState({ selected_file: event.target.files[0] }); 
    }; 

    render() {

        if(this.state.redirect_to_user)
        {
            return (
                <Redirect to={ "/users/" + this.props.router.match.params.id } />
            );
        }

        if(!this.state.loaded)
        {
            return (
                <React.Fragment>
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                </React.Fragment>
            );
        }

        if(!this.state.data)
        {
            return (
                <React.Fragment>
                    <Message color="red" fluid>This should'nt happen :(</Message>
                </React.Fragment>
            );
        }
        
        return (
            <React.Fragment>
                { this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> : null }
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Header size="large">
                                    <Icon name="edit"/>
                                    Editing profile
                                </Header>
                                <Divider/>
                                <Header>Avatar</Header>
                                <Image rounded bordered src={ this.state.data.avatar ? API_ADDRESS + "/img/" + this.state.data.avatar : Logo} style={{ width: "15rem", height: "15rem", marginBottom: "15px" }}/>
                                <Form style={{ marginBottom: "15px" }}>
                                    <Form.Group>
                                        <Form.Input width={5} type="file" placeholder="Avatar" onChange={this.onFileChange} />
                                        <Button color="green" onClick={this._AvatarUpload}>
                                            <Icon name="upload"/>
                                            Upload
                                        </Button>
                                    </Form.Group>

                                    <Header>Description</Header>
                                    <Header.Subheader>{this.state.description.length}/100 charachters</Header.Subheader>
                                    <Form.TextArea rows={10} name="description" value={this.state.description} onChange={this.handleChange}></Form.TextArea>

                                    <Header>Password</Header>
                                    <Form.Input width={5} name="password" type="password" placeholder="Password to change to" icon="lock" iconPosition="left" value={this.state.password} onChange={this.handleChange} />
                                </Form>
                                <Button color="green" onClick={this._UpdateUser}>
                                    <Icon name="save"/>
                                    Save
                                </Button>
                                <Button as={Link} to={ "/users/" + this.props.router.match.params.id }>Back</Button>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }
}

export default EditUser;