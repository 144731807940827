import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Divider, Icon } from 'semantic-ui-react';
import { GetData, API_Request, API_ADDRESS } from '../utils/auth';

class Builds extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: [],
            redirect_to_home: false,
        };

        this._GenerateCode = this._GenerateCode.bind(this);
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_home: true });
        }
        else
        {
            GetData("/invcodes",
            (res) => {
                res.data.sort(function(a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.date) - new Date(a.date);
                });
                this.setState({ loaded: true, data: res.data });
            },
            (err) => {
                this.setState({ loaded: true, error: err.data });
            });
        }
    }

    _GenerateCode()
    {
        GetData("/invcodes/generate",
        (res) => {
            this.componentDidMount();
        },
        (err) => {
            this.setState({ loaded: true, error: err.data });
        });
    }

    _DeleteCode(code_id)
    {
        API_Request("/invcodes/" + code_id, "DELETE", {}, {},
        (res) => {
            this.componentDidMount();
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    render() {

        if(this.state.redirect_to_home)
        {
            return (
                <Redirect to="/home" />
            );
        }

        const InvCodeComponent = ((props) => {
            return (
                <Card>
                    <Card.Content>
                        <Button floated="right" color="red" onClick={() => { this._DeleteCode(props.id) }}>Delete</Button>
                        <Card.Header>
                            {props.code}
                        </Card.Header>
                        <Card.Meta>
                            Generated { new Date(props.date).toLocaleString() }
                        </Card.Meta>
                        {
                            props.used ?
                                <Card.Meta>
                                    Used by {props.used_by}
                                </Card.Meta>
                                :
                                <Card.Meta>
                                    Not used
                                </Card.Meta>
                        }
                    </Card.Content>
                </Card>
            )
        });

        const content = this.state.data.map((code) => {
            return <InvCodeComponent key={code._id} id={code._id} code={code.code} used={code.used} used_by={ code.used_by ? code.used_by.username : null } date={code.date} />;
        });

        return (
            <React.Fragment>
                <Header as="h1">
                    Invite codes
                    <Button floated="right" color="green" onClick={this._GenerateCode}>
                        Generate code
                    </Button>
                </Header>
                <Divider/>
                {
                    !this.state.loaded ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> :
                        this.state.data.length ? <Card.Group itemsPerRow="1" style={{ maxHeight: "65.5rem", overflow: "auto" }}>{content}</Card.Group> :
                        <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Builds;