import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Divider, Icon } from 'semantic-ui-react';
import { GetData, API_ADDRESS } from '../utils/auth';

class Cheat extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            loaded_2: false,
            error: "",
            data: {},
            updates_data: []
        };
    }

    componentDidMount()
    {
        GetData("/cheats/" + this.props.router.match.params.id,
        (res) => {
            this.setState({ loaded: true, data: res.data });
        },
        (err) => {
            this.setState({ loaded: true, error: err.data });
        });

        GetData("/cheats/" + this.props.router.match.params.id + "/changes",
        (res) => {
            this.setState({ loaded_2: true, updates_data: res.data });
        },
        (err) => {
            this.setState({ loaded_2: true, error: err.data });
        });
    }

    render() {

        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        const updates = this.state.updates_data.map((update => {
            return (
                <Segment>
                    <Header>
                        <Header.Subheader>
                            { new Date(update.date).toLocaleString() }
                        </Header.Subheader>
                    </Header>
                    <Header.Subheader>Changelog</Header.Subheader>
                    <Container>
                        {update.changelog}
                    </Container>
                </Segment>
            );
        }));

        const content = (
            <React.Fragment>
                <Header as="h1">
                    {this.state.data.name}
                    {
                        !local_data.admin ? null : 
                            <Button floated="right" as={Link} to={ "/cheats/" + this.props.router.match.params.id + "/edit" }>
                                <Icon name="edit"/>
                                Edit
                            </Button>
                    }
                </Header>
                <Segment>
                    <Header>Description</Header>
                    <Container style={{ whiteSpace: "pre-wrap", maxHeight: "18.9rem", overflow: "auto" }}>
                        {this.state.data.description ? this.state.data.description : "Empty :(" }
                    </Container>
                </Segment>
                <Segment>
                    <Header>Updates</Header>
                    <Divider/>
                    <Container style={{ maxHeight: "52.5rem", overflow: "auto" }}>
                        {updates}
                    </Container>
                </Segment>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                {
                    !(this.state.loaded && this.state.loaded_2) ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> :
                        this.state.data ? content : <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Cheat;