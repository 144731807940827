import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Feed, Divider, Icon } from 'semantic-ui-react';
import { GetData, PostData, API_Request, API_ADDRESS } from '../utils/auth';

class CreateBuild extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: true,
            error: "",
            selected_file: null,
            succesfull_upload: "",
            redirect_to_builds: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this._BuildUpload = this._BuildUpload.bind(this);
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_builds: true });
        }
    }

    _BuildUpload()
    {
        const formData = new FormData();

        formData.append(
            "build",
            this.state.selected_file,
            this.state.selected_file.name
        );

        API_Request("/builds/upload", "POST", {}, formData,
        (res) => {
            this.setState({ succesfull_upload: res.data._id });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    onFileChange(event) { 
        this.setState({ selected_file: event.target.files[0] }); 
    }; 

    render() {

        if(this.state.redirect_to_builds)
        {
            return (
                <Redirect to="/builds" />
            );
        }

        if(this.state.succesfull_upload)
        {
            return (
                <Redirect to={ "/builds/" + this.state.succesfull_upload + "/edit" } />
            );
        }
        
        return (
            <React.Fragment>
                <Segment>
                    <Header size="large">
                        <Icon name="upload"/>
                        Uploading build
                    </Header>
                    <Divider/>
                    { this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> : null }
                    <Form style={{ marginBottom: "15px" }}>
                        <Form.Input width={5} type="file" placeholder="Avatar" onChange={this.onFileChange} />
                    </Form>
                    <Button color="green" onClick={this._BuildUpload}>
                        <Icon name="upload"/>
                        Upload
                    </Button>
                    <Button as={Link} to="/builds">Back</Button>
                </Segment>
            </React.Fragment>
        );
    }
}

export default CreateBuild;