import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Divider, Icon } from 'semantic-ui-react';
import { GetData, API_ADDRESS } from '../utils/auth';

import Logo from '../img/niksu.jpg';

class Users extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: []
        };
    }

    componentDidMount()
    {
        GetData("/users",
        (res) => {
            res.data.sort(function(a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.date) - new Date(a.date);
            });

            this.setState({ loaded: true, data: res["data"] });
        },
        (err) => {
            this.setState({ loaded: true, error: err["error"] });
        });
    }

    render() {

        const UserComponent = ((props) => {
            return (
                <Card as={Link} to={"/users/" + props.id}>
                    <Image src={props.avatar} style={{ width: "20rem", height: "15rem" }} />
                    <Card.Content>
                        <Card.Header>
                            {props.name}
                            { props.admin ? <Label attached="top left" color="red">Admin</Label> : null }
                        </Card.Header>
                        <Card.Meta>
                            Joined {new Date(props.date).toLocaleString()}
                        </Card.Meta>
                    </Card.Content>
                </Card>
            )
        });

        const content = this.state.data.map((user) => {
            return <UserComponent key={user._id} id={user._id} avatar={user.avatar ? API_ADDRESS + "/img/" + user.avatar : Logo} name={user.username} admin={user.admin} date={user.date} />
        });

        return (
            <React.Fragment>
                <Header as="h1">
                    <Icon name="user"/>
                    Users
                </Header>
                <Divider/>
                {
                    !this.state.loaded ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> :
                        this.state.data.length ? <Card.Group itemsPerRow="4" style={{ maxHeight: "65.5rem", overflow: "auto" }}>{content}</Card.Group> : <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Users;