import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Feed, Divider, Comment, Icon } from 'semantic-ui-react';
import { GetData, PostData, API_Request, API_ADDRESS } from '../utils/auth';

import Logo from '../img/niksu.jpg';

class User extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: {},
            comment: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this._Comment = this._Comment.bind(this);
    }

    componentDidMount()
    {
        GetData("/users/" + this.props.router.match.params.id,
        (res) => {
            res["data"]["comments"].sort((a, b) => new Date(b.date) - new Date(a.date));
            this.setState({ loaded: true, data: res.data });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.match.params.id !== prevProps.router.match.params.id)
        {
            this.componentDidMount();
        }
    }

    _Comment()
    {
        PostData("/users/" + this.props.router.match.params.id + "/comment", { comment: this.state.comment },
        (res) => {
            this.componentDidMount();
            this.setState({ comment: "" });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    _DeleteComment(comment_id)
    {
        API_Request("/users/" + this.props.router.match.params.id + "/comment/" + comment_id, "DELETE", {}, {},
        (res) => {
            this.componentDidMount();
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {

        if(!this.state.loaded)
        {
            return (
                <React.Fragment>
                    <Header as="h1">User</Header>
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                </React.Fragment>
            );
        }

        if(!this.state.data)
        {
            return (
                <React.Fragment>
                    <Message color="red" fluid>Found nothing :(</Message>
                </React.Fragment>
            );
        }

        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        const comments = this.state.data.comments.map((comment) => {
            return (
                <Comment key={comment._id}>
                    <Comment.Avatar src={ comment.user_id.avatar ? API_ADDRESS + "/img/" + comment.user_id.avatar : Logo } style={{ width: "3rem", height: "3rem" }} />
                    <Comment.Content>
                        <Comment.Author as={Link} to={ "/users/" + comment.user_id._id }>{comment.user_id.username}</Comment.Author>
                        <Comment.Metadata>
                            <div>{new Date(comment.date).toLocaleString()}</div>
                        </Comment.Metadata>
                        <Comment.Text>{comment.comment}</Comment.Text>
                        <Comment.Actions>
                            {
                                local_data.admin ? <Comment.Action onClick={() => { this._DeleteComment(comment._id) }}>Delete</Comment.Action> : local_data._id === comment.user_id._id ?
                                    <Comment.Action onClick={() => { this._DeleteComment(comment._id) }}>Delete</Comment.Action> : null
                            }
                        </Comment.Actions>
                    </Comment.Content>
                </Comment>
            );
        });

        return (
            <React.Fragment>
                { this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> : null }
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Segment>
                                <Image rounded bordered src={ this.state.data.avatar ? API_ADDRESS + "/img/" + this.state.data.avatar : Logo } style={{ width: "16rem", height: "16rem" }} ></Image>
                                <Header size="large">
                                    {this.state.data.username}
                                    { this.state.data.admin ? <Label horizontal color="red">Admin</Label> : null }
                                </Header>
                                Joined {new Date(this.state.data.date).toLocaleString()}
                            </Segment>
                        </Grid.Column>   
                        <Grid.Column width={ local_data._id !== this.props.router.match.params.id ? 12 : 10 }>
                            <Segment>
                                <Header>
                                    Description
                                </Header>
                                <Container style={{ whiteSpace: "pre-wrap", maxHeight: "18.9rem", overflow: "auto" }}>
                                    {this.state.data.description ? this.state.data.description : "Empty :(" }
                                </Container>
                            </Segment>
                        </Grid.Column>
                        {
                            local_data._id !== this.props.router.match.params.id ? null : 
                                <Grid.Column width={2}>
                                    <Button fluid as={Link} to={ "/users/" + this.props.router.match.params.id + "/edit" }>
                                        <Icon name="edit"/>
                                        Edit
                                    </Button>
                                </Grid.Column>
                        }
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Header>Comments</Header>
                                <Form>
                                    <Form.Group>
                                        <Form.Input width="15" name="comment" icon="comment" iconPosition="left" placeholder="Write a comment" value={this.state.comment} onChange={this.handleChange} />
                                        <Form.Button onClick={this._Comment}>Comment</Form.Button>
                                    </Form.Group>
                                    <Divider/>
                                    { this.state.data.comments.length ? <Comment.Group style={{ minWidth: "100%", maxHeight: "33.5rem", overflow: "auto" }}>{comments}</Comment.Group> : "No comments :(" }
                                </Form>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }
}

export default User;