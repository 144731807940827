import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Container, Menu, Button, Dimmer, Loader, Segment, Label } from 'semantic-ui-react';
import { Form, Grid, Header, Image, Message } from 'semantic-ui-react';

import logo from '../img/niksu.jpg';
import { API_ADDRESS, Logout, CheckAuth } from '../utils/auth';

class Page extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          redirect: false,
          loaded: false,
          error: "",
          activeItem: ""
        };

        this._Logout = this._Logout.bind(this);
    }
    
    componentDidMount()
    {
        let path_str = this.props.location.pathname;
        this.setState({ activeItem: path_str.substring(1, path_str.lenght) });

        CheckAuth(
        (res) => {
            this.setState({ redirect: false, loaded: true });
        },
        (err) => {
            this.setState({ redirect: true, loaded: true, error: err["error"] });
        });
    }

    _Logout()
    {
        Logout(
        (res) => {
            this.setState({ redirect: true });
        },
        (err) => {
            this.setState({ redirect: false, loaded: true, error: err["error"] });
        });
    }

    render() {

        if(this.state.redirect)
        {
            return ( <Redirect to="/" /> );
        }

        const handleItemClick = (e, { name }) => this.setState({ activeItem: name })

        const NavBar = () => {

            let name = "unknown";
            let avatar_url = logo;
            let admin = false;
            
            if(this.state.loaded)
            {
                let data = JSON.parse(window.sessionStorage.getItem("userdata"));
                name = data.username;
                avatar_url = data.avatar ? API_ADDRESS + "/img/" + data.avatar : logo;
                admin = data.admin;
            }

            return (
                <Segment style={{ padding: ".25em", marginBottom: ".5em" }}>
                    <Menu secondary>
                        <Menu.Item>
                            <Image bordered rounded src={avatar_url} style={{ width: "4rem", height: "4rem" }} />
                            <Label color="green">
                                Welcome, {name}!
                            </Label>
                        </Menu.Item>

                        <Menu.Item as={Link} to="/home" name="home" active={this.state.activeItem === "home"} onClick={handleItemClick} >
                            Home
                        </Menu.Item>

                        <Menu.Item as={Link} to="/users" name="users" active={this.state.activeItem === "users"} onClick={handleItemClick} >
                                Users
                        </Menu.Item>

                        <Menu.Item as={Link} to="/cheats" name="cheats" active={this.state.activeItem === "cheats"} onClick={handleItemClick} >
                            Cheats
                        </Menu.Item>

                        <Menu.Item as={Link} to="/ts3" name="ts3" active={this.state.activeItem === "ts3"} onClick={handleItemClick} >
                            Teamspeak
                        </Menu.Item>

                        {
                            !admin ? null :
                                <Menu.Item as={Link} to="/builds" name="builds" active={this.state.activeItem === "builds"} onClick={handleItemClick} >
                                    Builds
                                </Menu.Item>
                        }

                        {
                            !admin ? null :
                                <Menu.Item as={Link} to="/invcodes" name="invcodes" active={this.state.activeItem === "invcodes"} onClick={handleItemClick} >
                                    Invite codes
                                </Menu.Item>
                        }

                        <Menu.Item position="right">
                            <Button color="red" onClick={this._Logout}>Logout</Button>
                        </Menu.Item>
                    </Menu>
                </Segment>
            );
        }

        //Passing react router match, history, location props to content component and waiting while this page loads then load content
        const content = !this.state.loaded ? 
            <Dimmer active inverted>
                <Loader>Loading...</Loader>
            </Dimmer>
            : 
            <this.props.content router={ { match: this.props.match, history: this.props.history, location: this.props.location } } />;

        return (
            <React.Fragment>

                <Container style={{ marginBottom: "5%" }}>
                    <div style={{ position: "relative", top: "5vh", padding: "5px", borderRadius: "3px", background: "rgb(245, 245, 245)"}}>
                        <NavBar/>
                        
                        <Segment style={{ marginTop: ".5em", minHeight: "75vh", padding: "15px" }}>
                            { this.state.error ? <h2>{this.state.error}</h2> : content }
                        </Segment>
                    </div>
                </Container>

            </React.Fragment>
        );
    }
}

export default Page;