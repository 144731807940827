import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Message} from 'semantic-ui-react';
import { CheckAuth, PostData } from '../utils/auth';

import Logo from '../img/niksu.jpg';

class Register extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            error: "",
            message: "",
            username: "",
            password: "",
            invite_code: "",
            redirect_home: false
        };

        this.handleChange = this.handleChange.bind(this);
        this._Register = this._Register.bind(this);
    }

    componentDidMount()
    {
        CheckAuth(
            (res) => {
                this.setState({ redirect_home: true });
            },
            (err) => {
            return;
        });
    }
    
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    _Register()
    {
        PostData("/users", {
            username: this.state.username,
            password: this.state.password,
            invite_code: this.state.invite_code
         },
         (res) => {
             this.setState({ message: "Registered successfully" });
         },
         (err) => {
             this.setState({ error: err["error"] });
         });
    }
    
    render() {

        
        if(this.state.redirect_home)
        {
            return ( <Redirect to="/home" /> );
        }

        const display_msg = this.state.message ?
            <Message positive style={{ fontSize: "1rem" }}>{this.state.message}</Message> : this.state.error ?
            <Message negative style={{ fontSize: "1rem" }}>{this.state.error}</Message> : null;

        return (
            <React.Fragment>
        
                <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
                    <Grid.Column style={{ borderRadius: "3px", background: "rgb(245, 245, 245)", maxWidth: 220 }}>
                        <Form size="large">
                            <Image style={{ paddingBottom: "15px", borderRadius: "3px" }} src={Logo} size="tiny" centered />
                            {display_msg}
                            <Form.Input name="username" icon="user" iconPosition="left" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                            <Form.Input name="password" icon="lock" iconPosition="left" placeholder="Password" value={this.state.password} onChange={this.handleChange} type="password" />
                            <Form.Input name="invite_code" icon="key" iconPosition="left" placeholder="Invite code" value={this.state.invite_code} onChange={this.handleChange} />
                            <Button onClick={this._Register}>Register</Button>
                            <Button as={Link} to="/">Back</Button>
                        </Form>
                    </Grid.Column>
                </Grid>
            
            </React.Fragment>
        );
    }
}

export default Register;