import React from 'react';

import { List, Message, Header, Dimmer, Loader, Segment, Divider } from 'semantic-ui-react';
import { GetData } from '../utils/auth';

class Teamspeak extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            name: null,
            data: []
        };
    }

    componentDidMount()
    {
        GetData("/ts3/info",
        (res) => {
            if (res.data)
                this.setState({ loaded: true, name: res["data"]["server_name"], data: res["data"]["channels"] });
        },
        (err) => {
            this.setState({ loaded: true, error: err["error"] });
        });
    }

    render() {
        
        console.log(this.state.data);

        const ChannelComponent = ((props) => {
            return (
                <Segment compact>
                    <Header>
                        {props.name}
                        <Header.Subheader>
                            <List style={{paddingTop:"10px"}} bulleted items={props.clients} />
                        </Header.Subheader>
                    </Header>
                </Segment>
            )
        });

        const content = this.state.data.map((channel) => {
            return <ChannelComponent name={channel.name} clients={channel.clients} />
        });

        return (
            <React.Fragment>
                <Header as="h1">
                    {this.state.name}
                </Header>
                <Divider />
                Channels
                {
                    !this.state.loaded ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message>  :
                        this.state.data.length ? content : <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Teamspeak;