import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Divider, Icon } from 'semantic-ui-react';
import { GetData, API_ADDRESS } from '../utils/auth';

class Build extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: {},
            redirect_to_home: false
        };
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_home: true });
        }
        else
        {
            GetData("/builds/" + this.props.router.match.params.id,
            (res) => {
                this.setState({ loaded: true, data: res.data });
            },
            (err) => {
                this.setState({ loaded: true, error: err.data });
            });
        }
    }

    render() {

        if(this.state.redirect_to_home)
        {
            return (
                <Redirect to="/home" />
            );
        }

        if(!this.state.loaded)
        {
            return (
                <Dimmer active inverted><Loader>Loading...</Loader></Dimmer>
            );
        }

        const content = (
            <React.Fragment>
                <Header as="h1">
                    Build {this.state.data._id}
                    <Button floated="right" as={Link} to={ "/builds/" + this.props.router.match.params.id + "/edit" }>
                        <Icon name="edit"/>
                        Edit
                    </Button>
                    <Button floated="right" color="green" as="a" download href={ API_ADDRESS + "/builds/dl/" + this.state.data._id }>
                        <Icon name="download" />
                        Download
                    </Button>
                    <Header.Subheader>
                        Uploaded {new Date(this.state.data.date).toLocaleString()}
                    </Header.Subheader>
                    <Header.Subheader>
                        For {this.state.data.build_for ? this.state.data.build_for.name : "nothing :(" }
                    </Header.Subheader>
                </Header>
                <Segment>
                    <Header>Changelog</Header>
                    <Container style={{ whiteSpace: "pre-wrap", maxHeight: "18.9rem", overflow: "auto" }}>
                        {this.state.data.changelog ? this.state.data.changelog : "Empty :(" }
                    </Container>
                </Segment>
                <Segment>
                    <Header>Other info</Header>
                    <Container>
                        Filename {this.state.data.filename}
                    </Container>
                </Segment>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                {
                    this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> :
                        this.state.data ? content : <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Build;