import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Feed, Divider, Icon } from 'semantic-ui-react';
import { GetData, PostData, API_Request, API_ADDRESS } from '../utils/auth';

class EditBuild extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            loaded_2: false,
            error: "",
            data: {},
            cheats_data: [],
            changelog: "",
            cheat: "",
            redirect_to_build: false,
            redirect_to_builds: false
        };

        this.handleChange = this.handleChange.bind(this);
        this._UpdateBuild = this._UpdateBuild.bind(this);
        this._DeleteBuild = this._DeleteBuild.bind(this);
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_build: true });
        }
        else
        {
            GetData("/builds/" + this.props.router.match.params.id,
            (res) => {
                this.setState(() => {
                    let data = {
                        loaded: true, data: res.data,
                        changelog: res.data.changelog
                    };

                    if(res.data.build_for)
                    {
                        data.build_for = res.data.build_for._id
                    }

                    return data;
                 });
            },
            (err) => {
                this.setState({ loaded: true, error: err.error });
            });

            GetData("/cheats",
            (res) => {
                this.setState({ loaded_2: true, cheats_data: res.data });
            },
            (err) => {
                this.setState({ loaded_2: true, error: err.error });
            });
        }
    }

    _UpdateBuild()
    {
        let update_data = {
            changelog: this.state.changelog,
            build_for: this.state.build_for
        };

        API_Request("/builds/" + this.state.data._id, "PUT", { "Content-Type": "application/json" }, JSON.stringify(update_data),
        (res) => {
            this.setState({ redirect_to_build: true });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    _DeleteBuild()
    {
        API_Request("/builds/" + this.state.data._id, "DELETE", {}, {},
        (res) => {
            this.setState({ redirect_to_builds: true });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {

        if(this.state.redirect_to_build)
        {
            return (
                <Redirect to={ "/builds/" + this.props.router.match.params.id } />
            );
        }

        if(this.state.redirect_to_builds)
        {
            return (
                <Redirect to="/builds" />
            );
        }

        if(!(this.state.loaded && this.state.loaded_2))
        {
            return (
                <React.Fragment>
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                </React.Fragment>
            );
        }

        if(!this.state.data)
        {
            return (
                <React.Fragment>
                    <Message color="red" fluid>This should'nt happen :(</Message>
                </React.Fragment>
            );
        }
        
        return (
            <React.Fragment>
                <Segment>
                    <Header size="large">
                        <Icon name="edit"/>
                        Editing build
                    </Header>
                    <Divider/>
                    { this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> : null }
                    <Form style={{ marginBottom: "15px" }}>

                        <Header>Changelog</Header>
                        <Form.TextArea rows={5} name="changelog" value={this.state.changelog} onChange={this.handleChange}/>

                        <Header>Cheat</Header>
                        <Form.Select value={this.state.build_for} onChange={(event, data) => { this.setState({ build_for: data.value }) }} options={
                            this.state.cheats_data.map((cheat) => {
                                return { key: cheat._id, text: cheat.name, value: cheat._id }
                            })
                        } />
                    </Form>
                    <Button color="green" onClick={this._UpdateBuild}>
                        <Icon name="save"/>
                        Save
                    </Button>
                    <Button as={Link} to={ "/builds/" + this.props.router.match.params.id }>Back</Button>
                    <Button floated="right" color="red" onClick={this._DeleteBuild}>Delete</Button>
                </Segment>
            </React.Fragment>
        );
    }
}

export default EditBuild;