import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Feed, Divider, Icon } from 'semantic-ui-react';
import { GetData, PostData, API_Request, API_ADDRESS } from '../utils/auth';

class CreateCheat extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: true,
            error: "",
            name: "",
            description: "",
            process_name: "",
            admin_only: true,
            redirect_to_cheats: false
        };

        this.handleChange = this.handleChange.bind(this);
        this._CreateCheat = this._CreateCheat.bind(this);
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_cheats: true });
        }
    }

    _CreateCheat()
    {
        let create_data = {
            name: this.state.name,
            description: this.state.description,
            process_name: this.state.process_name,
            admin_only: this.state.admin_only
        };

        PostData("/cheats", create_data,
        (res) => {
            this.setState({ redirect_to_cheats: true });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {

        if(this.state.redirect_to_cheats)
        {
            return (
                <Redirect to="/cheats" />
            );
        }
        
        return (
            <React.Fragment>
                <Segment>
                    <Header size="large">
                        <Icon name="edit"/>
                        Creating cheat
                    </Header>
                    <Divider/>
                    { this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> : null }
                    <Form style={{ marginBottom: "15px" }}>
                        <Header>Name</Header>
                        <Form.Input name="name" value={this.state.name} onChange={this.handleChange} />

                        <Header>Description</Header>
                        <Form.TextArea rows={5} name="description" value={this.state.description} onChange={this.handleChange}/>

                        <Header>Process name</Header>
                        <Form.Input name="process_name" value={this.state.process_name} onChange={this.handleChange} />

                        <Header>Admin</Header>
                        <Form.Checkbox label="Is the cheat admin only" checked={this.state.admin_only} onChange={() => { this.setState({ admin_only: !this.state.admin_only }) }} />
                    </Form>
                    <Button color="green" onClick={this._CreateCheat}>
                        <Icon name="save"/>
                        Create
                    </Button>
                    <Button as={Link} to="/cheats">Back</Button>
                </Segment>
            </React.Fragment>
        );
    }
}

export default CreateCheat;