export const API_ADDRESS = "/api"; //http://192.168.1.25:8000

export async function Login(username, password, success, error)
{
  try
  {
    let res = await fetch(API_ADDRESS + "/users/auth", {
      method: "POST",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: username, password: password })
    });

    let json = await res.json();

    if(json["error"])
    {
      error(json);
    }
    else
    {
      window.sessionStorage.setItem("userdata", JSON.stringify(json["data"]));
      success(json);
    }
  }
  catch(err)
  {
    error({ error: err.message });
  }
}

export async function Logout(success, error)
{
  try
  {
    let res = await fetch(API_ADDRESS + "/users/unauth", {
      method: "GET",
      credentials: "same-origin"
    });

    let json = await res.json();

    if(json["error"])
    {
      error(json);
    }
    else
    {
      window.sessionStorage.removeItem("userdata");
      success(json);
    }
  }
  catch(err)
  {
    error({ error: err.message });
  }
}

export async function CheckAuth(success, error)
{
  try
  {
    let res = await fetch(API_ADDRESS + "/users/auth", {
      method: "GET",
      credentials: "same-origin"
    });

    let json = await res.json();

    if(json["error"])
    {
      error(json);
    }
    else
    {
      window.sessionStorage.setItem("userdata", JSON.stringify(json["data"]));
      success(json);
    }
  }
  catch(err)
  {
    error({ error: err.message });
  }
}

export async function GetData(path, success, error)
{
  try
  {
    let res = await fetch(API_ADDRESS + path, {
      method: "GET",
      credentials: "same-origin"
    });

    let json = await res.json();

    if(json["error"])
    {
      error(json);
    }
    else
    {
      success(json);
    }
  }
  catch(err)
  {
    error({ error: err.message });
  }
}

export async function PostData(path, data, success, error)
{
  try
  {
    let res = await fetch(API_ADDRESS + path, {
      method: "POST",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    });

    let json = await res.json();

    if(json["error"])
    {
      error(json);
    }
    else
    {
      success(json);
    }
  }
  catch(err)
  {
    error({ error: err.message });
  }
}

export async function API_Request(path, method, headers, body, success, error)
{
  try
  {
    let res = await fetch(API_ADDRESS + path, {
      method: method,
      headers: headers ? headers: null,
      credentials: "same-origin",
      body: body ? body : null
    });

    let json = await res.json();

    if(json["error"])
    {
      error(json);
    }
    else
    {
      success(json);
    }
  }
  catch(err)
  {
    error({ error: err.message });
  }
}