import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Divider, Icon } from 'semantic-ui-react';
import { GetData, API_ADDRESS } from '../utils/auth';

class Cheats extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: []
        };
    }

    componentDidMount()
    {
        GetData("/cheats",
        (res) => {
            this.setState({ loaded: true, data: res.data });
        },
        (err) => {
            this.setState({ loaded: true, error: err.data });
        });
    }

    render() {

        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        const CheatComponent = ((props) => {
            return (
                <Card as={Link} to={"/cheats/" + props.id}>
                    <Card.Content>
                        <Card.Header>
                            {props.name}
                        </Card.Header>
                        <Card.Meta>
                            Updated { props.date ? new Date(props.date).toLocaleString() : "never" }
                        </Card.Meta>
                    </Card.Content>
                </Card>
            )
        });

        const content = this.state.data.map((cheat) => {
            return <CheatComponent key={cheat._id} id={cheat._id} name={cheat.name} date={ cheat.build ? cheat.build.date : null } />
        });

        return (
            <React.Fragment>
                <Header as="h1">Client</Header>
                <Divider/>

                <Button  color="green" as="a" download href={ API_ADDRESS + "/client/dl" }>
                    <Icon name="download" />
                    Download Client
                </Button>
                <Divider/>
                <Header as="h1">
                    Cheats
                    { local_data.admin ? <Button floated="right" color="green" as={Link} to="/cheats/create">Create cheat</Button> : null }
                </Header>
                <Divider/>
                {
                    !this.state.loaded ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> :
                        this.state.data.length ? <Card.Group itemsPerRow="4" >{content}</Card.Group> : <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Cheats;