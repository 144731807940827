import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ParticlesJS from './components/Particles';

import Page from './components/Page';

import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/Home';

import Users from './pages/Users';
import User from './pages/User';
import EditUser from './pages/EditUser';

import Cheats from './pages/Cheats';
import Cheat from './pages/Cheat';
import CreateCheat from './pages/CreateCheat';
import EditCheat from './pages/EditCheat';

import Builds from './pages/Builds';
import Build from './pages/Build';
import CreateBuild from './pages/CreateBuild';
import EditBuild from './pages/EditBuild';

import InviteCodes from './pages/InviteCodes';

import Teamspeak from './pages/Teamspeak';

function App() {

  return (
    <React.Fragment>

      <ParticlesJS/>

      <BrowserRouter>
        <Switch>

          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />

          <Route exact path="/home" render={(props) => <Page {...props} content={Home} /> } />
          <Route exact path="/users" render={(props) => <Page {...props} content={Users} /> } />

          <Route exact path="/users/:id" render={(props) => <Page {...props} content={User} /> } />
          <Route exact path="/users/:id/edit" render={(props) => <Page {...props} content={EditUser} /> } />

          <Route exact path="/cheats" render={(props) => <Page {...props} content={Cheats} /> } />
          <Route exact path="/cheats/create" render={(props) => <Page {...props} content={CreateCheat} /> } />
          <Route exact path="/cheats/:id" render={(props) => <Page {...props} content={Cheat} /> } />
          <Route exact path="/cheats/:id/edit" render={(props) => <Page {...props} content={EditCheat} /> } />

          <Route exact path="/builds" render={(props) => <Page {...props} content={Builds} /> } />
          <Route exact path="/builds/create" render={(props) => <Page {...props} content={CreateBuild} /> } />
          <Route exact path="/builds/:id" render={(props) => <Page {...props} content={Build} /> } />
          <Route exact path="/builds/:id/edit" render={(props) => <Page {...props} content={EditBuild} /> } />

          <Route exact path="/invcodes" render={(props) => <Page {...props} content={InviteCodes} /> } />

          <Route exact path="/ts3" render={(props) => <Page {...props} content={Teamspeak} />} />
          
          <Route path="/" component={() => { return (
            <h1 style={{ position: "fixed", top: "40vh", left: "50vw", transform: "translateX(-50%) translateY(-50%)", color: "white", fontSize: "15vw" }}>
              404
            </h1>
          ) }}/>

        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;