import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Form, Grid, Image, Message } from 'semantic-ui-react';
import { Login as AuthLogin, CheckAuth } from '../utils/auth';

import Logo from '../img/niksu.jpg';

class Login extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          error: "",
          username: "",
          password: "",
          redirect_home: false
        };

        this.handleChange = this.handleChange.bind(this);
        this._Login = this._Login.bind(this);
    }

    componentDidMount()
    {
      CheckAuth(
        (res) => {
            this.setState({ redirect_home: true });
        },
        (err) => {
          return;
        });
    }

    handleChange(event) {
      this.setState({ [event.target.name]: event.target.value });
    }

    _Login()
    {
      AuthLogin(this.state.username, this.state.password,
      (res) => {
        this.setState({ redirect_home: true });
      },
      (err) => {
        this.setState({ error: err["error"] });
      });
    }

    render() {

      if(this.state.redirect_home)
      {
        return ( <Redirect to="/home" /> );
      }

      const error_msg = this.state.error ? <Message negative style={{ fontSize: "1rem" }}>{this.state.error}</Message> : null;

      return (
        <React.Fragment>

          <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
            <Grid.Column style={{ borderRadius: "3px", background: "rgb(245, 245, 245)", maxWidth: 220 }}>
              <Form size="large">
                <Image style={{ paddingBottom: "15px", borderRadius: "3px" }} src={Logo} size="tiny" centered />
                {error_msg}
                <Form.Input name="username" icon="user" iconPosition="left" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                <Form.Input name="password" icon="lock" iconPosition="left" placeholder="Password" value={this.state.password} onChange={this.handleChange} type="password" />
                <Button onClick={this._Login}>Login</Button>
                <Button as={Link} to="/register">Register</Button>
              </Form>
            </Grid.Column>
          </Grid>
    
        </React.Fragment>
      );

    }
}

export default Login;