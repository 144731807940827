import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Feed, Divider, Icon } from 'semantic-ui-react';
import { GetData, PostData, API_Request, API_ADDRESS } from '../utils/auth';

class EditCheat extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            loaded_2: false,
            error: "",
            data: {},
            builds_data: [],
            name: "",
            description: "",
            process_name: "",
            build: "",
            admin_only: false,
            redirect_to_cheat: false,
            redirect_to_cheats: false
        };

        this.handleChange = this.handleChange.bind(this);
        this._UpdateCheat = this._UpdateCheat.bind(this);
        this._DeleteCheat = this._DeleteCheat.bind(this);
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_cheat: true });
        }
        else
        {
            GetData("/cheats/" + this.props.router.match.params.id,
            (res) => {
                this.setState({ loaded: true, data: res.data,
                    name: res.data.name,
                    description: res.data.description,
                    process_name: res.data.process_name,
                    build: res.data.build_id,
                    admin_only: res.data.admin_only
                 });
            },
            (err) => {
                this.setState({ loaded: true, error: err.error });
            });

            GetData("/builds",
            (res) => {
                res.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                let new_data = [];
                for(let i = 0; i < res.data.length; i++) {
                    if(res.data[i].build_for) {
                        if(res.data[i].build_for === this.props.router.match.params.id) {
                            new_data.push(res.data[i]);
                        }
                    }
                }
                this.setState({ loaded_2: true, builds_data: new_data });
            },
            (err) => {
                this.setState({ loaded_2: true, error: err.error });
            });
        }
    }

    _UpdateCheat()
    {
        let update_data = {
            name: this.state.name,
            description: this.state.description,
            process_name: this.state.process_name,
            build_id: this.state.build,
            admin_only: this.state.admin_only
        };

        API_Request("/cheats/" + this.state.data._id, "PUT", { "Content-Type": "application/json" }, JSON.stringify(update_data),
        (res) => {
            this.setState({ redirect_to_cheat: true });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    _DeleteCheat()
    {
        API_Request("/cheats/" + this.state.data._id, "DELETE", {}, {},
        (res) => {
            this.setState({ redirect_to_cheats: true });
        },
        (err) => {
            this.setState({ loaded: true, error: err.error });
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {

        if(this.state.redirect_to_cheat)
        {
            return (
                <Redirect to={ "/cheats/" + this.props.router.match.params.id } />
            );
        }

        if(this.state.redirect_to_cheats)
        {
            return (
                <Redirect to="/cheats" />
            );
        }

        if(!(this.state.loaded && this.state.loaded_2))
        {
            return (
                <React.Fragment>
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                </React.Fragment>
            );
        }

        if(!this.state.data)
        {
            return (
                <React.Fragment>
                    <Message color="red" fluid>This should'nt happen :(</Message>
                </React.Fragment>
            );
        }
        
        return (
            <React.Fragment>
                <Segment>
                    <Header size="large">
                        <Icon name="edit"/>
                        Editing cheat
                    </Header>
                    <Divider/>
                    { this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> : null }
                    <Form style={{ marginBottom: "15px" }}>
                        <Header>Name</Header>
                        <Form.Input name="name" value={this.state.name} onChange={this.handleChange} />

                        <Header>Description</Header>
                        <Form.TextArea rows={5} name="description" value={this.state.description} onChange={this.handleChange}/>

                        <Header>Process name</Header>
                        <Form.Input name="process_name" value={this.state.process_name} onChange={this.handleChange} />

                        <Header>Build</Header>
                        <Form.Select value={this.state.build} onChange={(event, data) => { this.setState({ build: data.value }) }} options={
                            this.state.builds_data.map((build) => {
                                return { key: build._id, text: build._id, value: build._id }
                            })
                        } />

                        <Header>Admin</Header>
                        <Form.Checkbox label="Is the cheat admin only" checked={this.state.admin_only} onChange={() => { this.setState({ admin_only: !this.state.admin_only }) }} />
                    </Form>
                    <Button color="green" onClick={this._UpdateCheat}>
                        <Icon name="save"/>
                        Save
                    </Button>
                    <Button as={Link} to={ "/cheats/" + this.props.router.match.params.id }>Back</Button>
                    <Button floated="right" color="red" onClick={this._DeleteCheat}>Delete</Button>
                </Segment>
            </React.Fragment>
        );
    }
}

export default EditCheat;