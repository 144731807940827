import React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Card, Label, Divider, Icon } from 'semantic-ui-react';
import { GetData, API_ADDRESS } from '../utils/auth';

class Builds extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            loaded_2: false,
            error: "",
            data: [],
            cheats_data: [],
            build_filter: [],
            redirect_to_home: false,
        };
    }

    componentDidMount()
    {
        let local_data = JSON.parse(window.sessionStorage.getItem("userdata"));

        if(!local_data.admin)
        {
            this.setState({ redirect_to_home: true });
        }
        else
        {
            GetData("/builds",
            (res) => {
                res.data.sort(function(a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.date) - new Date(a.date);
                });
                this.setState({ loaded: true, data: res.data });
            },
            (err) => {
                this.setState({ loaded: true, error: err.data });
            });

            GetData("/cheats",
            (res) => {
                this.setState({ loaded_2: true, cheats_data: res.data });
            },
            (err) => {
                this.setState({ loaded_2: true, error: err.data });
            });
        }
    }

    render() {

        if(this.state.redirect_to_home)
        {
            return (
                <Redirect to="/home" />
            );
        }

        const BuildComponent = ((props) => {
            return (
                <Card as={Link} to={"/builds/" + props.id}>
                    <Card.Content>
                        <Card.Header>
                            {props.id}
                        </Card.Header>
                        <Card.Meta>
                            { new Date(props.date).toLocaleString() }
                        </Card.Meta>
                    </Card.Content>
                </Card>
            )
        });

        const content = this.state.data.filter((build) => {
            if(!this.state.build_filter.length) return true;
            if(this.state.build_filter.includes(build.build_for)) return true;
            return false;
        }).map((build) => {
            return <BuildComponent key={build._id} id={build._id} name={build._id} date={build.date} />;
        });

        return (
            <React.Fragment>
                <Header as="h1">
                    Builds
                    <Button floated="right" color="green" as={Link} to="/builds/create">
                        <Icon name="upload" />
                        Upload build
                    </Button>
                </Header>
                <Header.Subheader>
                    Filter builds by cheat
                </Header.Subheader>
                <Form.Select clearable multiple fluid value={this.state.build_filter} onChange={(event, data) => { this.setState({ build_filter: data.value }) }} options={
                        this.state.cheats_data.map((cheat) => {
                            return { key: cheat._id, text: cheat.name, value: cheat._id }
                        })
                } />
                <Divider/>
                {
                    !(this.state.loaded && this.state.loaded_2) ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message> :
                        this.state.data.length ? <Card.Group itemsPerRow="3" style={{ maxHeight: "65.5rem", overflow: "auto" }}>{content}</Card.Group> :
                        <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Builds;