import React from 'react';

import { Button, Form, Grid, Image, Message, Header, Dimmer, Loader, Segment, Container, Divider, Icon } from 'semantic-ui-react';
import { GetData } from '../utils/auth';

class Home extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            loaded: false,
            error: "",
            data: []
        };
    }

    componentDidMount()
    {
        GetData("/cheats/changes",
        (res) => {
            this.setState({ loaded: true, data: res["data"] });
        },
        (err) => {
            this.setState({ loaded: true, error: err["error"] });
        });
    }

    render() {
        
        const NewsComponent = ((props) => {
            return (
                <Segment>
                    <Header>
                        {props.name}
                        <Header.Subheader>
                            { new Date(props.date).toLocaleString() }
                        </Header.Subheader>
                    </Header>

                    <Header.Subheader>
                        Changelog
                    </Header.Subheader>
                    <Container>
                        {props.changelog}
                    </Container>
                </Segment>
            )
        });

        const content = this.state.data.map((cheat) => {
            return <NewsComponent key={cheat.id} name={cheat.name} date={cheat.date} changelog={cheat.changelog} />
        });

        return (
            <React.Fragment>
                <Header as="h1">
                    <Icon name="home"/>
                    Home
                </Header>
                <Divider/>
                <Header.Subheader>Latest updates</Header.Subheader>
                {
                    !this.state.loaded ? <Dimmer active inverted><Loader>Loading...</Loader></Dimmer> :
                        this.state.error ? <Message color="red" fluid>Error: {this.state.error}</Message>  :
                        this.state.data.length ? content : <Message color="red" fluid>Found nothing :(</Message>
                }
            </React.Fragment>
        );
    }
}

export default Home;